import { FC } from 'react'

import {
  PageTabTileSection as Props,
  PageTabTileSectionTile as TileProps,
} from '../../../../../types/graphql'
import { CenteredTileLayout } from '../../../CenteredTileLayout'
import {
  maxTileWidth,
  minTileWidth,
  numberOfTilesDesktop,
  numberOfTilesMobile,
  tileGapDesktop,
  tileGapMobile,
} from '../../../pages/Collection/sections/CollectionGridSection/DesktopFilterBar/VisualFiltersSection/VisualFilters'

import { PageTabTileSectionTile } from './PageTabTileSectionTile'

/**
 * PageNavTileSection is a tabbed interface.
 * - https://www.w3.org/TR/wai-aria-practices/#tabpanel
 *
 * - NavPanel aria role='tabpanel'
 * - NavTabButtons role='tablist'
 */
export const PageTabTileSection: FC<Props> = ({
  name,
  layoutVariantDesktop,
  layoutVariantMobile,
  tiles,
  tileVariantDesktop,
  tileVariantMobile,
}) => {
  return (
    <CenteredTileLayout
      layoutVariantDesktop={layoutVariantDesktop}
      layoutVariantMobile={layoutVariantMobile}
      tileGapDesktop={tileGapDesktop}
      tileGapMobile={tileGapMobile}
      minTileWidth={minTileWidth}
      maxTileWidth={maxTileWidth}
      tiles={tiles}
      keyFromTile={(o: TileProps, index: number) => `${index}--${o.id}`}
      renderTile={(o: TileProps) => (
        <PageTabTileSectionTile
          {...o}
          name={name}
          tileVariantDesktop={tileVariantDesktop}
          tileVariantMobile={tileVariantMobile}
          visibleTilesDesktop={numberOfTilesDesktop}
          visibleTilesMobile={numberOfTilesMobile}
        />
      )}
      visibleTilesDesktop={numberOfTilesDesktop}
      visibleTilesMobile={numberOfTilesMobile}
      liftedButtons
    />
  )
}
