import styled from 'styled-components'

import { Button, TypeStyle, fromMd } from '@syconium/little-miss-figgy'

const desktopAspectRatio = 1.784
const mobileAspectRatio = 2.132

export const Container = styled.section<{
  backgroundColor: string
  hasMediaDesktop: boolean
  textColor: string
}>`
  background: ${o => o.backgroundColor};
  color: ${o => o.textColor};
  text-align: center;

  ${fromMd} {
    display: flex;
    text-align: ${o => (o.hasMediaDesktop ? 'left' : 'center')};
  }
`

export const TextWrap = styled.div<{
  hasMediaDesktop: boolean
}>`
  padding: 20px ${o => o.theme.dimensions.layout.sidePadding.md}px;

  ${fromMd} {
    align-self: center;
    box-sizing: border-box;
    padding: ${o => o.theme.spacing(10)} calc(100% / 12) ${o => o.theme.spacing(5)} calc(100% / 12);
    width: ${o => (o.hasMediaDesktop ? '50%' : '100%')};
  }
`

export const Title = styled.h2`
  ${TypeStyle.css.headlineSmall};
`

export const SubtitleWrapper = styled.div`
  display: flex;
  justify-content: center;
`

export const AdditionalContentWrapper = styled.div``

export const Subtitle = styled.p`
  ${TypeStyle.css.bodyDefault};
  margin-top: 8px;

  ${fromMd} {
    font-size: 16px;
    margin-top: ${o => o.theme.spacing(5)};
    width: 849px;
  }
`

export const Cta = styled(Button)`
  margin-top: 16px;
`

export const ImageWrap = styled.div<{
  imagePlacement: string
}>`
  height: 0;
  order: ${o => (o.imagePlacement === 'right' ? '2' : '-1')};
  overflow: hidden;
  padding-top: calc(1 / ${mobileAspectRatio} * 100%);
  position: relative;

  ${fromMd} {
    padding-top: calc(1 / ${desktopAspectRatio} * 50%);
    width: 50%;
  }

  & > img,
  & > picture > img,
  & > video {
    height: 100%;
    left: 0;
    object-fit: cover;
    font-family: 'object-fit: cover;';
    position: absolute;
    top: 0;
    width: 100%;
  }
`

export const Video = styled.video`
  display: block;
`

// TODO after migration to Magnolia, unify with lib/shared-styles PlayButton
export const ModalVideoPlayButton = styled.button`
  background: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  box-shadow: 0 0 10px -5px black;
  height: 44px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 44px;

  &::after {
    content: '';
    display: block;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 10px solid black;
    height: 0;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-36%, -50%);
    width: 0;
  }
`
