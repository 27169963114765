import styled, { DefaultTheme } from 'styled-components'

import { TypeStyle, fromMd, untilMd } from '@syconium/little-miss-figgy'

import { StyledText } from '../../../__generated__/graphql/catalog/graphql'

const getThemeColor = (key: string, theme: DefaultTheme) => {
  switch (key) {
    case 'light':
      return theme.color.deprecated.white.primary
    case 'dark':
      return theme.color.deprecated.black.primary

    default:
      return theme.color.text.primary.on.background
  }
}

export const StyledTextOverlay = styled.div<{
  textColorDesktop: StyledText['textColorDesktop']
  textColorMobile: StyledText['textColorMobile']
  textContentMaxWidthDesktop: NonNullable<StyledText['textContentMaxWidthDesktop']>
  textContentMaxWidthMobile: NonNullable<StyledText['textContentMaxWidthMobile']>
  textAlignMobile: StyledText['textAlignMobile']
  textAlignDesktop: StyledText['textAlignDesktop']
  bottomMargin: NonNullable<StyledText['bottomMargin']>
  centerFloatContent: boolean
}>`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  letter-spacing: 0.6px;
  margin-bottom: ${o => o.bottomMargin}px;
  text-align: ${o => o.textAlignMobile || 'center'};

  ${untilMd} {
    color: ${o =>
      o.textColorMobile
        ? getThemeColor(o.textColorMobile, o.theme)
        : o.theme.color.text.primary.on.background};

    > .configurable-width {
      max-width: ${o => o.textContentMaxWidthMobile}px;
    }

    > * {
      ${o => (o.centerFloatContent || o.textAlignMobile !== 'left' ? 'margin-left: auto' : null)};
      ${o => (o.centerFloatContent || o.textAlignMobile !== 'right' ? 'margin-right: auto' : null)};
    }
  }

  ${fromMd} {
    color: ${o =>
      o.textColorDesktop
        ? getThemeColor(o.textColorDesktop, o.theme)
        : o.theme.color.text.primary.on.background};
    line-height: 170%;
    text-align: ${o => o.textAlignDesktop || 'center'};

    > .configurable-width {
      max-width: ${o => o.textContentMaxWidthDesktop}px;
    }

    > * {
      ${o => (o.centerFloatContent || o.textAlignDesktop !== 'left' ? 'margin-left: auto' : null)};
      ${o =>
        o.centerFloatContent || o.textAlignDesktop !== 'right' ? 'margin-right: auto' : null};
    }
  }
`

export const Eyebrow = styled.h3<{ includeBottomMargin: boolean }>`
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.15em;
  line-height: 1.25em;
  ${o => (o.includeBottomMargin ? 'margin-bottom: 16px;' : null)}
  text-transform: uppercase;

  ${fromMd} {
    font-size: 18px;
    ${o => (o.includeBottomMargin ? 'margin-bottom: 24px;' : null)}
  }
`

export const Title = styled(TypeStyle.HeadlineMedium)<{
  textAlignMobile: StyledText['textAlignMobile']
  textAlignDesktop: StyledText['textAlignDesktop']
}>`
  margin-bottom: 16px;
  text-align: ${o => o.textAlignMobile || 'center'};

  ${fromMd} {
    display: block;
    margin-bottom: 24px;
    text-align: ${o => o.textAlignDesktop || 'center'};
  }
`

export const Links = styled.div<{
  textAlignMobile: StyledText['textAlignMobile']
  textAlignDesktop: StyledText['textAlignDesktop']
}>`
  margin-top: ${o => o.theme.spacing(6)};
  margin-bottom: ${o => o.theme.spacing(6)};
  a {
    margin-top: ${o => o.theme.spacing(6)};
    margin-bottom: ${o => o.theme.spacing(6)};
  }

  text-align: ${o => o.textAlignMobile || 'center'};

  ${untilMd} {
    > :first-child {
      margin-top: 0px;
      margin-left: ${o => o.textAlignMobile === 'left' && '0'};
    }

    > :last-child {
      margin-bottom: 0px;
      margin-right: ${o => o.textAlignMobile === 'right' && '0'};
    }
  }

  ${fromMd} {
    text-align: ${o => o.textAlignDesktop || 'center'};

    > :first-child {
      margin-top: 0px;
      margin-left: ${o => o.textAlignDesktop === 'left' && '0'};
      margin-right: ${o => o.textAlignDesktop === 'right' && '0'};
    }

    > :last-child {
      margin-bottom: 0px;
      margin-right: ${o => o.textAlignDesktop === 'right' && '0'};
    }
  }

  a {
    display: inline-block;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0.15em;
    line-height: 23px;
    position: relative;
    text-align: center;
    text-transform: uppercase;

    &::after {
      border-bottom: 2px solid currentColor;
      bottom: 0;
      content: '';
      left: 0;
      position: absolute;
      width: 100%;
    }
  }
`

export const Paragraph = styled.p<{
  paragraphTextSize: StyledText['paragraphTextSize']
  includeBottomMargin: boolean
}>`
  ${o => (o.includeBottomMargin ? 'margin-bottom: 16px;' : null)}
  ${fromMd} {
    ${o => (o.includeBottomMargin ? 'margin-bottom: 24px;' : null)}
  }
  ${o => TypeStyle.css[o.paragraphTextSize]}
`
