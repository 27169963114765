import React from 'react'

import { RawHtmlSection } from '@syconium/magnolia/src/brunswick/components/page-sections/RawHtmlSection'

import { PageTab, PageTabSectionSection } from '../../../../../types/graphql'
import { PageTabTileSection } from '../PageTabTileSection'
import { getPanelIdForPageTab, getTabIdForPageTab } from '../utils'

import { Body } from './styles'

export const PageTabPanel: React.FC<{
  tab: PageTab
  name: string | undefined
}> = ({ tab, name }) => {
  function renderTabSection(section: PageTabSectionSection, index: number): JSX.Element | null {
    const key = `${section.id}-${index}`

    switch (section.__typename) {
      case 'PageTabTileSection':
        return <PageTabTileSection {...section} key={key} name={name} />
      case 'RawHtmlSection':
        return <RawHtmlSection {...section} key={key} />
      default:
        return null
    }
  }

  return (
    <Body aria-labelledby={getTabIdForPageTab(tab)} id={getPanelIdForPageTab(tab)} role='tabpanel'>
      {tab.tabSections.map((x, i) => renderTabSection(x, i))}
    </Body>
  )
}
