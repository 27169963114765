import styled from 'styled-components'

import { fromMd } from '@syconium/little-miss-figgy'
import { Body as RawHtmlSection } from '@syconium/magnolia/src/brunswick/components/page-sections/RawHtmlSection/styles'
import { sectionSideGapDesktop } from '@syconium/magnolia/src/brunswick/components/page-sections/styles'

export const Body = styled.div`
  ${RawHtmlSection} {
    padding: 0 ${o => o.theme.dimensions.layout.sidePadding.md}px;

    ${fromMd} {
      padding: 0 ${sectionSideGapDesktop}px;
    }
  }
`
