import styled, { css } from 'styled-components'

import { ResponsiveImage } from '@syconium/little-miss-figgy'
import { fromMd, untilMd } from '@syconium/little-miss-figgy'

import {
  PageTabTileSectionTileVariantDesktop,
  PageTabTileSectionTileVariantMobile,
} from '../../../../../../types/graphql'

export const tileContentWidthDesktop = 168
export const tileContentWidthMobile = 84

export const PageTabTileImage = styled(ResponsiveImage)<{
  tileVariantDesktop: PageTabTileSectionTileVariantDesktop
  tileVariantMobile: PageTabTileSectionTileVariantMobile
}>`
  ${untilMd} {
    border-radius: ${o => (o.tileVariantMobile === 'circle' ? '50%' : '6px')};
    display: ${o => o.tileVariantMobile === 'pill' && 'none'};
  }

  ${fromMd} {
    border-radius: ${o => (o.tileVariantDesktop === 'circle' ? '50%' : '6px')};
  }
`

export const Body = styled.figure<{
  tileVariantDesktop: PageTabTileSectionTileVariantDesktop
  tileVariantMobile: PageTabTileSectionTileVariantMobile
}>`
  ${o =>
    o.tileVariantMobile === 'pill' &&
    css`
      ${untilMd} {
        background: ${o.theme.color.deprecated.stoneLighter};
        border-radius: 1em;
        padding: 2px 0 7px;
      }
    `}
`

export const Figcaption = styled.figcaption<{
  isUppercase: boolean
}>`
  font-size: 12px;
  font-weight: bold;
  letter-spacing: ${o => (o.isUppercase ? '0.15em' : '0.05em')};
  line-height: 1.5;
  margin-top: 8px;
  text-align: center;
`
