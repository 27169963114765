import styled, { css } from 'styled-components'

import { TypeStyle, fromMd } from '@syconium/little-miss-figgy'
import { PageBannerSectionTextAlign } from '@syconium/magnolia/src/types/graphql/contentful-components/page-banner-section'

type TitleBaseProps = {
  textAlignMobile: PageBannerSectionTextAlign | null
  textAlignDesktop: PageBannerSectionTextAlign | null
  includeBottomMargin: boolean
}

const TitleBase = css<TitleBaseProps>`
  ${o => (o.includeBottomMargin ? 'margin-bottom: 16px;' : null)}
  text-align: ${o => o.textAlignMobile || 'center'};

  ${fromMd} {
    display: block;
    text-align: ${o => o.textAlignDesktop || 'center'};
    ${o => (o.includeBottomMargin ? 'margin-bottom: 24px;' : null)}
  }
`

export const TitleDefault = styled(TypeStyle.HeadlineSmall)<TitleBaseProps>`
  ${TitleBase}
`

export const TitleMedium = styled(TypeStyle.HeadlineMedium)<TitleBaseProps>`
  ${TitleBase}
`

export const TitleLarge = styled(TypeStyle.HeadlineLarge)<TitleBaseProps>`
  ${TitleBase}
`

export const TitleExtraLarge = styled(TypeStyle.HeadlineExtraLarge)<TitleBaseProps>`
  ${TitleBase}
`
