import React from 'react'

import { TitleDefault, TitleExtraLarge, TitleLarge, TitleMedium } from './styles'
import { Props } from './types'

export const PageBannerSectionTitle: React.FC<Props> = ({
  titleTextSize,
  className,
  isUppercase,
  ...props
}) => {
  switch (titleTextSize) {
    case 'h1':
      return (
        <TitleExtraLarge
          asTag={titleTextSize}
          className={className}
          isUppercase={isUppercase}
          {...props}
        >
          {props.children}
        </TitleExtraLarge>
      )
    case 'h2':
      return (
        <TitleLarge
          asTag={titleTextSize}
          className={className}
          isUppercase={isUppercase}
          {...props}
        >
          {props.children}
        </TitleLarge>
      )
    case 'h3':
      return (
        <TitleMedium
          asTag={titleTextSize}
          className={className}
          isUppercase={isUppercase}
          {...props}
        >
          {props.children}
        </TitleMedium>
      )
    default:
      return (
        <TitleDefault
          asTag={titleTextSize}
          className={className}
          isUppercase={isUppercase}
          {...props}
        >
          {props.children}
        </TitleDefault>
      )
  }
}
