import styled from 'styled-components'

import { headerHeight } from '@syconium/magnolia/src/brunswick/constants'

export const Body = styled.ul`
  display: flex;
  height: ${headerHeight};
`

export const Tab = styled.button<{
  textColor?: string | null
}>`
  align-items: center;
  align-self: stretch;
  color: ${o => o.textColor};
  cursor: pointer;
  display: flex;
  font-size: 13px;
  height: 100%;
  letter-spacing: 0.65px;
  line-height: 18px;
  position: relative;
  user-select: none;

  > b {
    font-weight: 700;
    padding: 0 10px;
  }

  &:hover {
    opacity: 0.9;
  }

  &::after {
    bottom: 0;
    content: '';
    display: block;
    height: 5px;
    left: 0;
    position: absolute;
    width: 100%;
  }

  &[aria-selected='true'] {
    cursor: default;

    &:hover {
      opacity: 1;
    }

    &::after {
      background: currentColor;
    }
  }
`
