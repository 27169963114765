import React from 'react'

import { PageTabSection as Props } from '../../../../types/graphql'

import { Template } from './Template'
import { useViewModel } from './useViewModel'

export const PageTabSection: React.FC<Props & { index?: number }> = props => (
  <Template {...useViewModel(props)} />
)
