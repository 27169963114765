import React, { FC, ReactNode, useCallback } from 'react'

import { trackEvent } from '@syconium/magnolia/src/lib/analytics'
import { isUppercase } from '@syconium/magnolia/src/lib/utils'

import { StyledText } from '../../../__generated__/graphql/catalog/graphql'
import { MaybeSpaLink } from '../../../lib/adapters/next-routing-service/MaybeSpaLink'
import { PageBannerSectionTitle } from '../../page-sections/PageBannerSection/Title/Title'

import { Eyebrow, Links, Paragraph, StyledTextOverlay } from './styles'

type StyledTextElement = 'eyebrow' | 'title' | 'paragraph' | 'custom' | 'links'

export const ContentfulStyledText: FC<
  StyledText & {
    defaultMaxWidth?: number
    defaultBottomMargin?: number
    additionalCustomContent?: ReactNode
    centerFloatContent?: boolean
  }
> = ({
  defaultMaxWidth = 9999,
  defaultBottomMargin = 0,
  title,
  titleTextSize,
  paragraph,
  paragraphTextSize,
  eyebrow,
  textColorMobile,
  textColorDesktop,
  textContentMaxWidthMobile,
  textContentMaxWidthDesktop,
  textAlignMobile,
  textAlignDesktop,
  bottomMargin,
  links,
  additionalCustomContent,
  centerFloatContent,
}) => {
  const includeBottomMargin = useCallback(
    (element: StyledTextElement) => {
      const includedElements: StyledTextElement[] = []
      if (eyebrow) includedElements.push('eyebrow')
      if (title) includedElements.push('title')
      if (paragraph) includedElements.push('paragraph')
      if (additionalCustomContent) includedElements.push('custom')
      if (links.length > 0) includedElements.push('links')
      const indexOfElement = includedElements.indexOf(element)
      return indexOfElement > -1 && indexOfElement < includedElements.length - 1
    },
    [additionalCustomContent, eyebrow, links.length, paragraph, title]
  )

  return (
    <StyledTextOverlay
      textColorDesktop={textColorDesktop}
      textColorMobile={textColorMobile}
      textContentMaxWidthDesktop={textContentMaxWidthDesktop ?? defaultMaxWidth}
      textContentMaxWidthMobile={textContentMaxWidthMobile ?? defaultMaxWidth}
      textAlignMobile={textAlignMobile}
      textAlignDesktop={textAlignDesktop}
      bottomMargin={bottomMargin ?? defaultBottomMargin}
      centerFloatContent={centerFloatContent ?? false}
    >
      {eyebrow && (
        <Eyebrow
          includeBottomMargin={includeBottomMargin('eyebrow')}
          className='configurable-width'
        >
          {eyebrow}
        </Eyebrow>
      )}

      {title && (
        <PageBannerSectionTitle
          titleTextSize={titleTextSize}
          className='configurable-width'
          isUppercase={isUppercase(title)}
          textAlignMobile={textAlignMobile}
          textAlignDesktop={textAlignDesktop}
          includeBottomMargin={includeBottomMargin('title')}
        >
          {title}
        </PageBannerSectionTitle>
      )}

      {paragraph && (
        <Paragraph
          includeBottomMargin={includeBottomMargin('paragraph')}
          paragraphTextSize={paragraphTextSize ?? 'bodyDefault'}
          className='configurable-width'
        >
          {paragraph}
        </Paragraph>
      )}

      {additionalCustomContent}

      {links.length > 0 && (
        <Links
          textAlignMobile={textAlignMobile}
          textAlignDesktop={textAlignDesktop}
          className='configurable-width'
        >
          {links.map((link, index) => (
            <MaybeSpaLink
              key={`${link.id}-${index}`}
              href={link.url}
              {...trackEvent({
                category: 'page-banner-section',
                action: `click cta ${index}`,
                label: link.text,
                value: link.url,
              })}
            >
              {link.text}
            </MaybeSpaLink>
          ))}
        </Links>
      )}
    </StyledTextOverlay>
  )
}
