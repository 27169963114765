import React from 'react'

import { Media, isUppercase } from '@syconium/little-miss-figgy'

import { PageTab, PageTabSection as Props } from '../../../../types/graphql'

import { PageTabButtons } from './PageTabButtons'
import { PageTabPanel } from './PageTabPanel'
import { Body, Title, TitleBar } from './styles'

export type TemplateProps = Props & {
  activeTab: PageTab
  index?: number
  getOnClickTab: (tab: PageTab) => (event: React.MouseEvent<HTMLElement>) => void
}

export const Template: React.FC<TemplateProps> = ({
  activeTab,
  getOnClickTab,
  hideOnDesktop,
  hideOnMobile,
  name,
  id,
  index,
  tabs,
  title,
}) => {
  const showTabs = tabs.length > 1
  const titleId = `PageTabSection__${id}__Title`

  const layoutVariantMobile =
    activeTab.tabSections[0]?.__typename === 'PageTabTileSection'
      ? activeTab.tabSections[0].layoutVariantMobile
      : undefined

  const showTitlebar = !!title || tabs.length > 1

  const body = (
    <Body {...{ index, layoutVariantMobile, showTitlebar }}>
      {(title || showTabs) && (
        <TitleBar>
          <Title asTag='h5' id={titleId} isUppercase={isUppercase(title)}>
            {title}
          </Title>
          {showTabs && (
            <PageTabButtons {...{ activeTab, getOnClickTab, tabs }} ariaLabelledBy={titleId} />
          )}
        </TitleBar>
      )}
      <PageTabPanel tab={activeTab} name={name} />
    </Body>
  )

  if (hideOnDesktop && hideOnMobile) return null
  if (hideOnDesktop) return <Media lessThan='md'>{body}</Media>
  if (hideOnMobile) return <Media greaterThanOrEqual='md'>{body}</Media>
  return body
}
